export const ClientConfig = {
  ar: {
    clientname: "arkansas",
    layout: "basic",
    useWaveLanguageSelections: true,
    showLanguageDropdown: true,
    crsLanguageSupport: {
      en: "English",
      es: "Spanish",
      ar: "Arabic",
      mh: "Marshallese",
      ru: "Russian",
      vi: "Vietnamese",
      ksw: "S'Gaw Karen",
      chi: "Simplified Chinese",
      pt: "Portuguese",
      so: "Somali",
      sw: "Swahili",
    },
    crsLanguageKeys: {
      en: "en",
      es: "es",
      ar: "ar",
      mh: "mh",
      ru: "ru",
      vi: "vi",
      ksw: "ksw",
      chi: "chi",
      pt: "pt",
      so: "so",
      sw: "sw",
    },
    showInbox: true,
  },
  cambium: {
    clientname: "cambium",
    layout: "basic",
    fetchHistoricalData: true,
  },
  ct: {
    clientname: "connecticut",
    layout: "basic",
  },
  de: {
    clientname: "delaware",
    layout: "basic",
    useWaveLanguageSelections: true,
  },
  fl: {
    clientname: "florida",
    layout: "full",
    crsLanguageSupport: { en: "English", es: "Spanish" },
    crsLanguageKeys: { en: "en", es: "es" },
    progressChartSettings: {
      removeConnectedLines: true,
    },
    useWaveLanguageSelections: true,
  },
  hi: {
    clientname: "hawaii",
    layout: "basic",
    showVideoReports: true,
    crsLanguageSupport: { en: "English", es: "Spanish" },
    crsLanguageKeys: { en: "en", es: "es" },
  },
  ia: {
    clientname: "iowa",
    layout: "basic",
  },
  id: {
    clientname: "idaho",
    layout: "basic",
  },
  in: {
    clientname: "indiana",
    layout: "basic",
  },
  la: {
    clientname: "louisiana",
    layout: "basic",
  },
  mt: {
    clientname: "montana",
    layout: "basic",
  },
  ne: {
    clientname: "nebraska",
    layout: "basic",
  },
  nh: {
    clientname: "newhampshire",
    layout: "basic",
    crsLanguageSupport: { en: "English", es: "Spanish" },
    crsLanguageKeys: { en: "en", es: "es" },
  },
  nd: {
    clientname: "northdakota",
    layout: "basic",
  },
  oh: {
    clientname: "ohio",
    layout: "basic",
  },
  or: {
    clientname: "oregon",
    layout: "basic",
  },
  ri: {
    clientname: "rhodeisland",
    layout: "basic",
  },
  sc: {
    clientname: "southcarolina",
    layout: "basic",
  },
  sd: {
    clientname: "southdakota",
    layout: "basic",
  },
  tx: {
    clientname: "texas",
    layout: "full",
    fetchHistoricalData: true,
    fetchEocScores: true,
    useWaveLanguageSelections: true,
  },
  ut: {
    clientname: "utah",
    layout: "basic",
  },
  vt: {
    clientname: "vermont",
    layout: "basic",
  },
  vi: {
    clientname: "virginislands",
    layout: "basic",
    showLanguageDropdown: true,
    crsLanguageSupport: { en: "English", es: "Spanish" },
    crsLanguageKeys: { en: "en", es: "es" },
  },
  wa: {
    clientname: "washington",
    layout: "basic",
  },
  wv: {
    clientname: "westvirginia",
    layout: "full",
    crsLanguageSupport: { en: "English", es: "Spanish" },
    crsLanguageKeys: { en: "en", es: "es" },
    reportingCategoriesSettings: {
      forceShowPerformanceColumn: true,
    },
    detailedViewTabsStartingYear: 2023,
  },
  wy: {
    clientname: "wyoming",
    layout: "basic",
  },
};
